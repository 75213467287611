import { Component } from "@angular/core";

@Component({
  selector: "app-hero-slider",
  templateUrl: "./hero-slider.component.html",
  styleUrls: ["./hero-slider.component.scss"],
})
export class HeroSliderComponent {
  slides = [
    {
      link: "/winckelmans",
      component: "WinckelmansComponent",
      img: "../../../../assets/img/home_slider/hs_winckelmans.webp",
      title: "Winckelman Tegels",
      description: "Perfecte balans tussen traditie en modern",
      position: "35%",
    },
    {
      link: "/rawstones",
      component: "RawstonesComponent",
      img: "../../../../assets/img/home_slider/hs_raw_stones.webp",
      title: "Raw Stones",
      description: "Tijdloze charme met een robuuste uitstraling",
      position: "75%",
    },
    {
      link: "/houtenvloeren",
      component: "HoutenvloerenComponent",
      img: "../../../../assets/img/home_slider/hs_houten_vloeren.webp",
      title: "Houten vloeren",
      description: "Natuurlijke houten vloeren, puur en stijlvol ",
    },
  ];

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 840, // Adjust for tablets and below
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };

  slickInit(e) {
    console.log("slick initialized");
  }

  breakpoint(e) {
    console.log("breakpoint");
  }

  constructor() {}
}
