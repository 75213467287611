import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-content-slider",
  templateUrl: "./content-slider.component.html",
  styleUrls: ["./content-slider.component.scss"],
})
export class ContentSliderComponent implements OnInit {
  slides = [
    {
      img: "assets/img/asso_hout_vloer.jpg",
      title: "Houten vloeren",
      text: "Een houten vloer brengt warmte, sfeer en karakter in elke ruimte. Kies voor een robuuste brede plank of een stijlvol parketpatroon, passend bij jouw interieur. Of het nu een woning of bedrijfspand is, een houten vloer biedt eindeloze mogelijkheden en geeft elke ruimte een authentieke uitstraling.",
    },
    {
      img: "assets/img/asso_raw_stones.jpg",
      title: "Raw Stones",
      text: "Raw Stones vloeren geven elke ruimte een warme, karaktervolle uitstraling. Handgemaakt van natuurlijke materialen, zijn ze duurzaam, slijtvast en eenvoudig in onderhoud. Perfect voor thuis én commerciële ruimtes, zodat je jarenlang kunt genieten van stijl en kwaliteit..",
    },
    {
      img: "assets/img/asso_winckel.jpg",
      title: "Winckelmans tegels",
      text: "De unieke en sfeervolle Winckelmans tegels zijn tijdloos en van een uitmuntende kwaliteit. Deze dubbelhard gebakken tegels zijn extreem sterk, vorstbestendig en slijtvast, perfect voor binnen én buiten. Met diverse kleuren en patronen creëer je een stijlvol designstatement in elke ruimte.",
    },
  ];

  slideConfig = {
    slidesToShow: 3, // Show 3 slides at a time
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 824, // Tablets
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // Mobile
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  slickInit(e) {
    console.log("slick initialized");
  }

  breakpoint(e) {
    console.log("breakpoint");
  }

  afterChange(e) {
    console.log("afterChange");
  }

  beforeChange(e) {
    console.log("beforeChange");
  }

  constructor() {}

  ngOnInit() {}
}
