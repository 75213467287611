import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SlickCarouselModule } from "ngx-slick-carousel";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { HeaderComponent } from "./blox/header/header.component";
import { HeroSliderComponent } from "./blox/hero-slider/hero-slider.component";
import { ContentSliderComponent } from "./blox/content-slider/content-slider.component";
import { HeaderLogoComponent } from "./blox/header-logo/header-logo.component";
import { FooterComponent } from "./blox/footer/footer.component";
import { CtaFloorComponent } from './blox/cta-floor/cta-floor.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    HeroSliderComponent,
    ContentSliderComponent,
    HeaderLogoComponent,
    FooterComponent,
    CtaFloorComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SlickCarouselModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
