import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cta-floor',
  templateUrl: './cta-floor.component.html',
  styleUrls: ['./cta-floor.component.scss']
})
export class CtaFloorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
